.body{
    padding: 12px;
    padding-bottom: 36px;
}

.navigation {
  position: fixed !important;
  bottom: 40px;
  right: 100px;
  padding: 40px !important;
}

.navigation-hide { 
  animation: fadeOut ease 0.5s;
  -webkit-animation: fadeOut ease 0.5s;
  opacity: 0;
}

.navigation .icon {
  font-size: 40px;
}

.popout {
  animation: popout 0.5s ease;
  -webkit-animation: popout 0.5s ease;
}

@keyframes popout {
  from{transform:scale(0)}
  80%{transform:scale(1.2)}
  to{transform:scale(1)}
}
@-webkit-keyframes popout {
  from{-webkit-transform:scale(0)}
  80%{-webkit-transform:scale(1.2)}
  to{-webkit-transform:scale(1)}
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}