.work-card {
  padding: 25px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 20px 8px #d0d0d0;
  width: 44%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.work-logo {
    height: 80px;
}

.work-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.work-dates {
    color: black;
}

.company-name {
  font-weight: bold;
}

.work-desc {
    white-space: break-spaces;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    overflow: hidden ;
    text-overflow: ellipsis;
    max-width: 100%;
}

.project-tags{
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
}

.cssanimation, .cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span { display: inline-block }

.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  to { opacity: 1 }
}

@media only screen and (max-width: 1180px) {
  .work-card{
      width: 40%;
  }
}

@media only screen and (max-width: 920px) {
    .work-card{
        width: 100%;
    }
}