.skill-card{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 8px;
}

.skill-icon {
    font-size: 80px;
}

.skill-name{
    font-size: 18px;
}

@media only screen and (max-width: 1024px) {
    .skill-card {
      width: max-content;
    }
    .skill-icon {
      font-size: 60px;
    }
}
