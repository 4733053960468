.about {
    padding-bottom: 48px;
}
.languages {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
}
.about-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.picture {
    height: 520px;
    border-top-left-radius: 800px;
    border-bottom-right-radius: 800px;
    border-bottom-left-radius: 800px;
    border-top-right-radius: 800px;
}
.about-info {
    font-size: 24px;
}
.about-photo {
    margin: 24px;
}
.info-name {
    color: blue;
    font-size: 32px;
}

@media only screen and (max-width: 1420px) {
  .about-top{
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
}

@media only screen and (max-width: 820px) {
    .about-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .picture {
        height: 250px;
    }
    .about-info {
        font-size: 20px;
    }
    .info-name {
        font-size: 26px;
    }
}