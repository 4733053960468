.header{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 12px;
    position: relative;
    align-items: center;
}
.menu{
    background-color: white;
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.menu-icon{
    font-size: 24px;
}

.mobile-menu{
    display: none;
}
.web-menu{
    display: flex;
}

.logo{
    display: flex;
    font-size: 36px;
    color: blue;
    font-weight: 800;
}


.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 4px 8px;
  margin: 6px 6px;
}

.toggle-text {
  padding-top: 2.5px;
  text-align: center;
  color: white;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 1;    
  left: 13%;
  padding-top: 25px;
  padding-left: 30px;
  width: 71.8%;
  background-color: white;
}

@media only screen and (max-width: 1024px) {
  .sticky {
    position: fixed;
    top: 0;    
    left: 1%;
    padding-top: 25px;
    padding-left: 30px;
    width: 93%;
    z-index: 1;
    background-color: white;
  }
}

@media only screen and (max-width: 720px) {
    .mobile-menu{
        display: flex;
    }
    .web-menu{
        display: none;
    }
    .logo{
        font-size: 24px;
    }
    .sticky {
      position: fixed;
      top: 0;    
      left: 0;
      padding-top: 25px;
      padding-left: 30px;
      width: 90%;
      z-index: 1;
      background-color: white;
    }
}

  