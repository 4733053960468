.skills {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.skills-section {
    margin: 15px;
}

.skills-container {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.skills-section-title {
    font-size: 24px;
    font-weight: 600;
}
.skills-list {
    padding: 0
}
@media only screen and (max-width: 720px) {
    .skills-section {
      margin: 10px;
      width: 40%;
    }
}